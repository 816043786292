<template>
    <div class="container" :style="{'margin-top':statusBarHeight+'px'}">
        <header>
            <router-link
                :to="{ name: 'profileIndex' }"
                v-slot="{ navigate }"
                custom
            >
                <div class="back-button" @click="navigate">
                    <i class="icon-leftarrow"></i>
                    返回
                </div>
            </router-link>
        </header>
        <router-link
            v-for="card in cardList"
            :key="card.id"
            :to="{ name: 'patientDetail', params: { id: card.id } }"
            custom
            v-slot="{ href }"
        >
            <a class="record" :href="href">
                <span class="title">{{ card.bankCradNumber }}</span>
                <i class="icon-rightarrow"></i>
            </a>
        </router-link>
    </div>
</template>

<script>
import { bankcardList } from "@/api";
export default {
    data: function () {
        return {
            cardList: [],
            statusBarHeight:0,
        };
    },
    created: function () {
        bankcardList().then((res) => {
            this.cardList = this.$camel(res.data.dataList);
        });
        // ios头部内容适配问题
		if(this.$store.state.statusBarHeight > 0){
            this.statusBarHeight = parseInt(this.$store.state.statusBarHeight);
            this.statusBarHeights = this.statusBarHeights + parseInt(this.$store.state.statusBarHeight);
        }
    },
};
</script>

<style lang="scss" scoped>
header {
    padding: 16px;
    @include flexr-between-center;
    font-size: 24px;
    font-weight: $semi-bold;
    color: $black;
}
.record {
    display: block;
    text-decoration: none;
    margin-bottom: 16px;
    background-color: $green-300;
    color: #fff;
    padding: 16px;
    @include flexr-between-center;
    .title {
        font-size: 21px;
        font-weight: $semi-bold;
    }
}
</style>